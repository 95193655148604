/* CSS FOR NAVBAR */

/* .navbar {
    width: 100%;
  } */

  .header {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 2em 0;
    list-style: none;
    text-align: center;
  }
  
  .header-logo {
    display: inline-block;
    max-width: 150px;
    border-radius: 45px;
    padding: 1em;
  }
  .header-title {
    display: inline-block;
    height: auto;
    width: 300px;
    min-height: 50px;
    margin: 0 1em;
  }
  
  .navbar-header {
    width: 25%;
    justify-content: flex-end;
    background-color: #353535;
    margin-top: 6em;
  }

  .nav {
    width: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid #fff;
  }
  .nav > .nav-header {
    flex: 0.95;
    padding-left: 1rem;
  }
  .nav > .nav-header > .nav-title {
    font-size: 22px;
    color: #fff;
  }
  .nav > .nav-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
  }
  .nav > .nav-list > li {
    list-style-type: none;
  }
  .nav > .nav-list > li a {
    text-decoration: none;
    color: #fff;
    font-weight: 700;
  }
  .nav > .nav-list > li a:hover {
    cursor: pointer;
    border-bottom: 1px solid #fff;
  }

  .nav > #nav-check {
      display: none;
  }

  .nav-btn > label > span, .nav-title {
    cursor: pointer;
  }

  /* RESPONSIVE */
  @media (max-width: 850px) {
    .navbar-header {
      margin-top: 0;
    }
    .nav > .nav-btn {
      display: inline-block;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      padding-top: 0.2rem;
      height: 50px;
      border-bottom: 1px solid #fff;
      background-color: #1A237E;
    }
    .nav > .nav-btn > label {
      display: inline-block;
      width: 50px;
      height: 50px;
      padding: 13px;
    }
    .nav > .nav-btn > label > span {
      display: block;
      width: 25px;
      height: 6px;
      border-top: 3px solid #eee;
    }
    .nav > .nav-list {
      position: absolute;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      width: 35%;
      background-color: #1A237E;
      height: 0;
      transition: all 0.3s ease-in;
      top: 40px;
      left: 0;
      overflow: hidden;
      z-index: 5;
      padding: 0;
    }
    .nav > .nav-list > li {
      width: 100%;
      margin-top: 1.5rem;
      text-align: center;
    }
    .nav > #nav-check:checked ~ .nav-list {
      /* height: calc(100vh - 500px); */
      height: 100%;
    }
  }

html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}
body {
  font-family: proxima-nova, Helvetica, sans-serif;
  font-weight: 400;
  /* color: #404040; */
  color: #fff;
  min-height: 100%;
  background-color: #353535;
  /* background: #f2efec;
  background-color: #E6E6E6; #daa520; */
}

.App {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
}

.wrapper {
  /* width: 70%; */
  padding: 0 1em 1em 1em;
}

.content-wrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: center;
  align-self: center;
  /* margin: 1em 0 1em 0; */
  /* width: 100%;  */
}

.wrapper-fill-empty {
  width: 25%;
}

/* HOME */

.filter-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
#category-list {
  padding: 5px;
}



  /* RESPONSIVE */

@media all and (max-width: 1000px) {

}

@media all and (max-width: 850px) {
  
}

@media all and (max-width: 600px) {
  .content-wrapper {
    flex-direction: column;
  }
  .wrapper {
    margin: 0 auto;
  }
}

@media all and (max-width: 450px) {
  .content-wrapper {
    margin: 0;
  }

}
/* CSS FOR GAMES COMPONENT */

/* CSS HEX PALETTE
--aureolin: #eae125ff;
--caput-mortuum: #552c2cff;
--redwood: #9d4d4dff;
--cerulean: #337ca0ff;
--slate-blue: #7067cfff; */

html {
  /* scrollbar-gutter: stable; */
}

.flex-container {
  min-height: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.game-header {
    text-align: center;
    font-weight: 500;
    font-family: proxima-nova, Helvetica, sans-serif;
    letter-spacing: 0.05rem;
    line-height: 1.15;
    margin: 1em 0 1em 0;
  }
  
  .collapse {
      display: none;
    }
  
    .collapse.in {
      display: block;
    }
  
  
  #games {
    width: 100%;
  }

  .game-expanded {
    width: 100%;
  }


  .game-feature > img:hover, #games:hover {
    cursor: pointer;
    box-shadow: 0 8px 12px 0 rgba(0, 0, 0, 0.2);
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    -ms-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .game-feature > h2 { 
    text-align: center;
    margin: 0;
    padding: 10px 0;
    background-color: #dd4444; /* cf4e4e */
    color: #f6f4f1;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.2rem;
  }

  .grid {
    display: flex;
    flex-flow: row wrap;
    gap: 1rem;
    /* padding: 1rem; */
    margin: 0 auto;
    max-width: 1000px;
  }
  
  .grid-item {
    flex: 1 1 calc(30%); 
    box-sizing: border-box; 
    margin-bottom: 1rem;
  }
  
  .full-width {
    flex: 1 1 100%; /* Full width for the first item */
    margin-bottom: 1rem; /* Space below the full-width item */
  }
  
  .game-container {
    border: 1px solid #00000030;
    border-radius: 8px;
    overflow: hidden;
    /* background: #eae125; */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }

  .img-container {
    position: relative;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  
  .poster {
    height: 18em;
    width: 100%;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
    transition: opacity 0.7s ease;
  }  

  .icon-container {
    background: linear-gradient(to bottom, #4343431c, #000000);
    align-content: center;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 2rem;
    /* visibility: hidden; */
  }

  .brand-android, .brand-windows {
    margin-left: 1rem;
    font-size: 160%;
  }

  .brand-android { color: #3ddc84; }
  .brand-windows { color: #00bcf2; }
  

  /* MODAL */

  .modal-game {
    width: 600px;
    height: 300px;
    background: #e9e9e9!important;
    color: #404040;
    border-radius: 0.5rem;
  }

  .modal-game h2 {
    font-size: 175%;
    margin-bottom: 0;
  }

  .modal-game h3 {
    margin-bottom: 0.7rem;
    font-size: 125%;
  }

  .modal-game-image {
    align-self: center;
  }
  .modal-poster {
    object-fit: cover;
    border-radius: 5px;
    max-width: 150px;
  }

  .modal-game-overlay {
    background-color: #3333338a;
  }

  .modal-game-info {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 2em;
    /* width: 75%; */
  }

  .modal-game-footer {
    width: 100%;
    margin-top: auto;
  }

  .modal-game-hr {
    border-width: 5px;
    border-color: #dd4444;
  }
  .modal-game-info hr {
    border-color: #ffffff05;
    border-width: 1px;
    margin: 0;
  }
  hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, .1);
    margin: 24px 0;
    padding: 0;
  }

  .react-responsive-modal-closeButton {
    background-color: #b6b6b6!important;
    border-radius: 50%;
  }
  .react-responsive-modal-closeButton:hover {
    background-color: #c0c0c0;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  
  /* RESPONSIVE */

  @media all and (max-width: 1000px) {
    #games {
      display: flex;
      justify-content: center;
      flex-direction: column;
    }
    .grid-item {
      flex-basis: calc(40%);
    }
  }

  @media all and (max-width: 850px) {
    .grid-item {
      flex: 1 1 calc(45%); 
    }
    #games {
      display: flex;
      justify-content: center;
    }
    .navbar-header, .wrapper-fill-empty {
      width: unset;
    }
    .wrapper {
      width: 90%;
    }
    .game-header {
      margin-top: 3em;
    }
  }

  @media all and (max-width: 600px) {
    .grid { 
      gap: 0;
      padding: 0;
    }
    .grid-item {
      flex: 1 1 calc(100%); 
    }
    #games {
      display: flex;
      justify-content: center;
    }
    .modal-game {
      width: 300px;
      height: auto;
    }
    .modal-game-info {
      margin-left: 0;
    }
  }
  
  @media all and (max-width: 400px) {
    .grid-item {
      flex: 1 1 calc(100%);
    }
    #games {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .modal-game {
      width: auto;
      height: auto;
    }
  }
/*  CSS FOR FOOTER COMPONENT */

#footer { background-color: #1b1b1b; }
.footer-wrap {
  display: flex;
  /*flex-flow: column wrap;*/
  /*justify-content: space-between; only if using logo */
  justify-content: center;
  align-items: center;
}

#footer li { display: inline-block; margin: 0 10px; }
#footer li::after {
    display: block;
    content: attr(title);
    font-weight: bold;
    height: 0;
    overflow: hidden;
    visibility: hidden;
}
#footer li span {
    content: attr(title);
    font-weight: bold;
    height: 0;
}

.social-icons { margin-right: 0; }

.footer-navbar {
  padding: 0;
  margin-bottom: 20px;
  list-style: none;
  list-style-image: none;
  text-decoration: none; 
  align-self: flex-end;
}
.footer-navbar a { text-decoration: none; color: #ccc; }
.social-icons { color: #ccc; font-weight: bold; }
.at-symbol-text { color: #767528; }

.hover-effect-links {  cursor: pointer; font-weight: bold; transition: color .2s ease-in; } 
.social-icons:hover .social-icons-text { color: #fff; }
.social-icons:hover .at-symbol-text { color: #fffc36; }
.espen-homepage:hover { color: #fffc36; }

/* .github:hover span.TextGit { color: #fff }
.github:hover span.TextHub { color: #000 }
.twitter:hover span.TextTwitter { color: #00acee }
.youtube:hover span.TextYou { color: #c4302b }
.youtube:hover span.TextTube { color: #fff } */

.footer-logo { align-self: center; }
.footer-logo img {
  max-width: 100%;
  max-height: 50px;
  height: auto;
}